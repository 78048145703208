import { Controller } from "stimulus";
import dialogPolyfill from 'dialog-polyfill';

export default class extends Controller {

  static get targets() {
    return [
      'modalContent'
    ];
  }

  initialize() {
    dialogPolyfill.registerDialog(this.element);
    this.toggleModal();
  }

  toggleModal() {
    const isModalOpen = this.isModalOpen(this.element);
    if (!isModalOpen) {
      this.openModal();
    }
    else if (isModalOpen) {
      this.closeModal();
    }
  }

  openModal() {
    this.element.showModal();
  }

  closeModal() {
    this.element.close();
  }

  isModalOpen(modal) {
    return modal.hasAttribute('open');
  }

  onDismiss(e) {
    e.preventDefault();
  }
}