import { Controller } from "stimulus";
import dialogPolyfill from 'dialog-polyfill';

export default class extends Controller {

  static get targets() {
    return [
      'modalContent',
      'leaveSiteLink'
    ];
  }

  initialize() {
    dialogPolyfill.registerDialog(this.element);
    this.whitelistedUrls = this.element.dataset.whitelistedDomains.split(',');
  }

  handleDocumentClick(event) {
    const clickedElement = event.target;

    // Check if the clicked element is an anchor tag
    if (clickedElement.tagName !== 'A') {
      return;
    }

    try {
      // Check if modal needs to be shown
      if (!this.isExternalURL(clickedElement.href) || this.isWhitelistedUrl(clickedElement.href)) {
        return;
      }
      this.leaveSiteLinkTarget.href = clickedElement.href;
      event.preventDefault();
      this.openModal();
    }
    catch {
      console.info('Something went wrong checking URL for external-ness');
    }
  }

  leaveSite(e) {
    e.stopPropagation();
    this.closeModal();
  }

  isExternalURL(url) {
    const currentURL = window.location.hostname;
    const parsedURL = new URL(url);

    // Compare the hostnames
    return currentURL !== parsedURL.hostname;
  }

  isWhitelistedUrl(url) {
    const parsedURL = new URL(url);

    // Check for the hostname
    return this.whitelistedUrls.includes(parsedURL.hostname);
  }

  toggleModal() {
    const isModalOpen = this.isModalOpen(this.element);
    if (!isModalOpen) {
      this.openModal();
    }
    else if (isModalOpen) {
      this.closeModal();
    }
  }

  openModal() {
    this.element.showModal();
  }

  closeModal() {
    this.element.close();
  }

  isModalOpen(modal) {
    return modal.hasAttribute('open');
  }

  backdropDismiss(e) {
    if (!e.currentTarget.isEqualNode(e.target)) {
      return;
    }

    const rect = this.modalContentTarget.getBoundingClientRect();
    if (e.clientY < rect.top || e.clientY > rect.bottom ||
      e.clientX < rect.left || e.clientX > rect.right) {
      this.closeModal();
    }
  }
}