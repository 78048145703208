import { Controller } from "stimulus";

export default class extends Controller {

  static get targets() {
    return [
      'weights', 'doseLevels', 'selectedWeight'
    ];
  }

  connect() {
    // Set the initial dose display based on any pre-selected weight element
    const initialSelected = this.element.querySelector('.selected');
    if (initialSelected) this.updateDose(initialSelected);
  }

  initialize() {
    // Event listener for clicking on weight options
    this.weightsTarget.addEventListener('click', (event) => {
      const weightElement = event.target.closest('.weight-range');
      if (weightElement) {
        this.updateDose(weightElement);
        this.updateSelectedWeight(weightElement);
      }
    });
  }

  updateDose(weightElement) {
    const levels = [1, 2, 3, 4, 5];
    levels.forEach(level => {
      const doseSpan = this.doseLevelsTarget.querySelector(`li:nth-child(${level}) .dose`);
      const doseMaxIndicator = this.doseLevelsTarget.querySelector(`li:nth-child(${level}) .max-level-indicator`);
      const doseValue = weightElement.getAttribute(`data-dose-level-${level}`);
      const maxDoseLevel = parseInt(weightElement.getAttribute(`data-dose-level-max`));
      
      // Show/hide max-level indicator based on max dose level
      if (level < maxDoseLevel) {
        doseMaxIndicator.classList.add('hidden');
      } else {
        doseMaxIndicator.classList.remove('hidden');
      }
      
      doseSpan.textContent = doseValue;
    });

    // Display the selected weight in the appropriate target
    const selectedWeight = weightElement.querySelector('span').textContent;
    this.selectedWeightTarget.innerText = selectedWeight;
  }

  updateSelectedWeight(selectedElement) {
    // Remove 'selected' class from all weight elements within this instance
    this.weightsTarget.querySelectorAll('.weight-range').forEach(element => {
      element.classList.remove('selected');
    });
    // Add 'selected' class to the clicked weight element
    selectedElement.classList.add('selected');
  }
}