import focus from "focus-visible";
import inert from "wicg-inert";

import { Application } from "stimulus";
import { utils } from "yuzu-plugin-core";
import options from '../../../yuzu.config.js';
import { definitionsFromContext } from "stimulus/webpack-helpers";

const application = Application.start();
utils.setup(application);

const context = require.context('./controllers', true, /\.js$/);
application.load(definitionsFromContext(context));
import animate from '@yuzu/yuzu-plugin-base/_dev/_source/js/animation/animateIn';

options.plugins.forEach(plugin => {
    if(plugin.initScripts) {
      var contextItems = plugin.initScripts(application);
      contextItems.forEach((context) => {
        application.load(definitionsFromContext(context));
      });
    }
});


const initialise = () => {
  animate();
}

if(application.isFrontend()) {
  document.addEventListener('YUZU:load', () => {
    initialise();
  });
}
else {
  initialise();
}