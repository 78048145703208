import { Controller } from "stimulus";
import CC from "CookieConsent";

export default class extends Controller {

  static get targets() {
    return [
    ];
  }
  gtmLoaded = false;    

  initialize() {
    const content = JSON.parse(this.element.dataset.content);

    window.cookieconsent.initialise({
      palette: {
        popup: { background: "#004F80" },
        button: { background: "#E38030", "text": "#FFFFFF", class: "demo" },
      },
      type: "opt-in",
      content: {
        ...content
      },
      revokable: true,
      onStatusChange: function (status) {
        console.log(this.hasConsented() ?
          'enable cookies' : 'disable cookies');
      },
      law: {
        regionalLaw: false,
      },
      location: true,
      onInitialise: function (status) {
        var type = this.options.type;
        var didConsent = this.hasConsented();
        //console.log('onInitialise info', type, didConsent);
        if (type == 'opt-in' && didConsent) {
          // enable cookies
          console.log('init yes');
          (function (w, d, s, l, i) {
              w[l] = w[l] || []; w[l].push({
                  'gtm.start':
                      new Date().getTime(), event: 'gtm.js'
              }); var f = d.getElementsByTagName(s)[0],
                  j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                  'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', gtmId);
        }
        if (type == 'opt-out' && !didConsent) {
          // disable cookies
          //console.log('init no');
        }
      },
      onStatusChange: function (status, chosenBefore) {
        var type = this.options.type;
        var didConsent = this.hasConsented();
        console.log('onStatusChange info', type, didConsent);
        if (type == 'opt-in' && didConsent) {
          // enable cookies
          console.log('init other - yes');
          
          (function (w, d, s, l, i) {
              w[l] = w[l] || []; w[l].push({
                  'gtm.start':
                      new Date().getTime(), event: 'gtm.js'
              }); var f = d.getElementsByTagName(s)[0],
                  j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                  'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', gtmId);

        }
        if (type == 'opt-out' && !didConsent) {
          // disable cookies
          console.log('init other - no');
        }
      },
      onRevokeChoice: function () {
        var type = this.options.type;
        //console.log('onRevokeChoice info', type);
        if (type == 'opt-in') {
          // disable cookies
          console.log('init onRevokeChoice - opt in');
          
          (function (w, d, s, l, i) {
              w[l] = w[l] || []; w[l].push({
                  'gtm.start':
                      new Date().getTime(), event: 'gtm.js'
              }); var f = d.getElementsByTagName(s)[0],
                  j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                  'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', gtmId);
          
        }
        if (type == 'opt-out') {
          // enable cookies
          //console.log('init onRevokeChoice - opt out');
        }
      },
      animateRevokable: false
    });
  } 

}